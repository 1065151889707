import axios from "axios"

export const axiosBackEnd = axios.create({
  baseURL: "https://imgkr.com/api",
  withCredentials: true,
})

export const axiosImgkr = axios.create({
  baseURL: "https://imgkr.com/api",
  headers: {
    "Content-Type": "multipart/form-data",
  },
})

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const toBlob = (base64, fileType) => {
  const bytes = window.atob(base64)
  let n = bytes.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bytes.charCodeAt(n)
  }
  return new Blob([u8arr], { type: fileType })
}

export const hasCookie = key => {
  const cookie = document.cookie.split(";")
  for (let item of cookie) {
    let dict = item.split("=")
    if (dict[0] === key) {
      return true
    }
  }
  return false
}

export const getCookie = key => {
  const cookie = document.cookie.split(";")
  for (let item of cookie) {
    let dict = item.split("=")
    if (dict[0] === key) {
      return dict[1]
    }
  }
  return null
}