export const IMG_API = "https://imgkr.com/api/v2/files/upload"
export const USER_API = "https://imgkr.com/api/user"
export const USER_LOGOUT_API = "https://imgkr.com/api/user/logout"
export const LOGIN_URL = "https://www.94ant.com/auth/qrauth?redirect_uri=https://imgkr.com/api/users/login"
// export const IMG_API = "http://localhost:8887/api/v2/files/upload"
// export const USER_API = "http://localhost:8887/api/user"
// export const USER_LOGOUT_API = "http://localhost:8887/api/user/logout"
// export const LOGIN_URL = "https://www.94ant.com/auth/qrauth?redirect_uri=http://localhost:8887/api/users/login"

export const MAX_UPLOAD_NUM = 5

export const TOKEN = "token"
export const USERNAME = "username"
export const AVATAR = "avatar"
export const USERID = "userId"
